import api from "./interceptor";

export const getAllQuiz = async (currentLanguage) => {
    try {
        const response = await api.get(`quiz?lang_id=${currentLanguage}`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}
// not being used
// export const getAllQuestion = async (quizId) => {
//     try {
//         const response = await api.get(`quiz/question/all/${quizId}`)
//         return response.data.data
//     } catch (e) {
//         console.log(e);
//     }
// }


// this is missing in backend and not being used in frontend
// export const getAllLevel = async () => {
//     try {
//         const response = await api.get(`level`)
//         return response.data.data
//     } catch (e) {
//         console.log(e);
//     }
// }

export const getAllOpponent = async () => {
    try {
        const response = await api.get(`quiz/findOpponent`)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}

export const getLeaderboard = async () => {
    try {
        const response = await api.get(`quiz/leaderboard`)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}
export const getQuiz = async (quizId, levelId, currentLanguage) => {
    try {
        const response = await api.get(`quiz/${quizId}/${levelId}?lang_id=${currentLanguage}`)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}

export const getHistory = async (currentLanguage) => {
    try {
        const response = await api.get(`quiz/history?lang_id=${currentLanguage}`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getProfile = async () => {
    try {
        const response = await api.get(`auth/profile`)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}
export const updateProfile = async (formData) => {
    try {
        const response = await api.patch(`auth/profile`, formData)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}

export const updateScore = async (quizId, levelId, totalPoints) => {
    try {
        const response = await api.post(`quiz/points/${quizId}/${levelId}`, totalPoints)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}

export const getAllCompetition = async () => {
    try {
        const response = await api.get(`competition`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}
export const getOneCompetition = async (id) => {
    try {
        const response = await api.get(`competition/${id}`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}

export const enterChampionship = async (request) => {
    try {
        const response = await api.post(`competition`, request)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}
export const getCompetitionInfo = async (id) => {
    try {
        const response = await api.get(`competition/detail/${id}`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}

export const getCompetitions = async (randomQuestion, currentLanguage) => {
    try {
        const response = await api.get(`competition/quiz/${randomQuestion}?lang_id=${currentLanguage}`)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}
export const updateScoreCompetitions = async (competitionId, totalPoints) => {
    try {
        const response = await api.post(`competition/points/${competitionId}`, totalPoints)
        return response.data.data
    } catch (e) {
        console.log(e);
    }
}

export const getTopCompetition = async (competitionId) => {
    try {
        const response = await api.get(`competition/top/participants/${competitionId}`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}

export const getFunFact = async () => {
    try {
        const response = await api.get(`quiz/getFunFact`)
        return response.data
    } catch (e) {
        console.log(e);
    }
}